:root {
    --default: #F9F9F9;
    --white: #ffffff;
    --black: #000000;
    --gray: #E5E5E9;
}

.react-calendar {
    background-color: var(--white);
    color: var(--black);
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
    font-family: 'Inter Regular';
    border: none;
    border-radius: 4px;
    padding: 10px;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: var(--gray);
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

abbr[title] {
    text-decoration: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: var(--gray);
}

.react-calendar__tile--now {
    background: var(--gray);
    font-weight: bold;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: var(--gray);
    font-weight: bold;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: var(--gray);
}

.react-calendar__tile--active {
    background: var(--gray);
    font-weight: bold;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--gray);
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: var(--gray);
}

.react-calendar__tile--range {
    background: var(--gray);
}

.react-calendar__tile--rangeStart,  .react-calendar__tile--rangeEnd {
    background: var(--gray);
    color: var(--black);
}

.react-calendar__month-view__days__day--weekend,.react-calendar__tile--active {
    color: var(--black);
}